import axios from "axios";

export default async function uploadTemporaryAudio(p_baseUrl, p_cartNumber, p_audioBlob) {
    const formData = new FormData();
    
    // Get the current date and format as YYYY_MM_DD_HH_mm
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    
    // Create the file name in the format: temporary_audio_YYYY_MM_DD_HH_mm.wav
    const fileName = `temporary_audio_${year}_${month}_${day}_${hours}_${minutes}_${seconds}.wav`;
    
    // Append the audio blob and cart number
    formData.append("audio", p_audioBlob, fileName);
    formData.append("cartNumber", p_cartNumber);  // Include the cart number

    try {
        const response = await axios.post(`${p_baseUrl}/admin/uploadTemporaryAudio`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 200) {
            return response.data;
        }

        return 'error';
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code outside 2xx range
            console.error("Server responded with an error:", error.response.data);
            console.error("Status code:", error.response.status);
            console.error("Headers:", error.response.headers);
        } else if (error.request) {
            // The request was made, but no response was received
            console.error("No response received from the server:", error.request);
        } else {
            // Something else happened in making the request that triggered an error
            console.error("Error while making request:", error.message);
        }
        return 'error';
    }
}
