import React from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import CartCamerasCarousel from "../../cartCameras/CartCamerasCarousel";
import CartActionButtons from "../../cartActionButtons/CartActionButtons";
import OperatorTreatmentResponseService from "../../../services/operatorTreatmentResponseEvent";
import styles from "./modalOperatorItem.module.css";

export default function ModalOperatorItem({
  operatorCart,
  activeop,
  onClose,
  descriptionsMap,
  actionsMap,
  actionsTitleMap,
  setEventsContainerOpen
}) {
  if (!operatorCart) return null;

  const { cart_number, operators, cartAddTime } = operatorCart;

  const handleSolveOperator = async (action, operator) => {
    try {
      const response = await OperatorTreatmentResponseService(
        cart_number,
        operator.operator_treatment_event_id,
        action
      );

      if (response === "ok") {
        Swal.fire({
          icon: "success",
          title: `Operation ${action} succeeded!`,
          position: "center",
          confirmButtonColor: "#00A7EE",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `Operation ${action} failed: ${response}`,
          position: "center",
          confirmButtonColor: "#00A7EE",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `An error occurred during ${action}!`,
        text: error.message,
        position: "center",
        confirmButtonColor: "#00A7EE",
      });
    }
  };

  return (
    <Modal
      open={!!operatorCart}
      onClose={onClose}
      aria-labelledby="modal-operator-item-title"
      aria-describedby="modal-operator-item-description"
    >
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <span id="modal-operator-item-title">
            Cart {cart_number} - {descriptionsMap[activeop.operator_treatment_event_id]}
          </span>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.modal_body}>
          <div style={{ fontStyle: "italic", marginBottom: "8px" }}>
            Added at: {new Date(cartAddTime).toLocaleTimeString()}
          </div>
          <div>
            {operators?.length ? (
              operators.map((operator) => (
                <div
                  key={operator.operator_treatment_event_id}
                  style={{
                    marginBottom: "8px",
                    padding: "8px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                >
                  <strong>Please choose how to handle the operator:</strong>
                  <div style={{ marginTop: "8px" }}>
                    {actionsMap[operator.operator_treatment_event_id]?.map((action) => (
                      <button
                        key={action}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleSolveOperator(action, operator);
                        }}
                        style={{
                          margin: "4px",
                          padding: "8px 12px",
                          backgroundColor: "var(--carteav)",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        {actionsTitleMap[action] ? actionsTitleMap[action] : action}
                      </button>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No operators currently</p>
            )}
          </div>
          <CartCamerasCarousel cartId={cart_number} />
          <CartActionButtons cartInfo={operatorCart} onFocusCB={(e)=>{ setEventsContainerOpen(false); onClose(e);}} />
        </div>
      </div>
    </Modal>
  );
}
