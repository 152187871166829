import React from "react";
import Draggable from "react-draggable";
import useAuth from "../../../hooks/Auth/useAuth";
import Swal from "sweetalert2";
import styles from "./event.module.css";
import CartCamerasCarousel from "../../cartCameras/CartCamerasCarousel";
import CamerasGrid from "../../cartCameras/CamerasGrid";


import CartActionButtons from "../../cartActionButtons/CartActionButtons";
import OperatorTreatmentResponseService from "../../../services/operatorTreatmentResponseEvent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function DraggableOperatorItem({
  operatorCart,
  activeop,
  onClose,
  descriptionsMap,
  actionsMap,
  actionsTitleMap,
  setEventsContainerOpen
}) {
  const { relevantUrl } = useAuth();
  if (!operatorCart) return null;

  const { cart_number, operators, cartAddTime } = operatorCart;

  const handleSolveOperator = async (action, operator) => {
    try {
      const response = await OperatorTreatmentResponseService(
        cart_number,
        operator.operator_treatment_event_id,
        action,
        relevantUrl
      );

      if (response === "ok") {
        Swal.fire({
          icon: "success",
          title: `Operation ${action} succeeded!`,
          position: "center",
          confirmButtonColor: "#00A7EE",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `Operation ${action} failed: ${response}`,
          position: "center",
          confirmButtonColor: "#00A7EE",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `An error occurred during ${action}!`,
        text: error.message,
        position: "center",
        confirmButtonColor: "#00A7EE",
      });
    }
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) onClose();
  };

  return (
    <Draggable handle=".drag-handle, .draggable-cart-action-buttons" cancel="button">
      <div
        className={styles.draggable_operator_container}
        style={{
          position: "fixed",
          top: "10%",
          left: "10%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          padding: "16px",
          border: "1px solid #333",
          backgroundColor: "#fff",
          zIndex: 9999,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <div
          className="drag-handle"
          style={{
            cursor: "move",
            backgroundColor: "#f5f5f5",
            padding: "8px 16px",
            borderBottom: "1px solid #ccc",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Cart {cart_number} - {descriptionsMap[activeop.operator_treatment_event_id]}
          </span>

          <IconButton
            onClick={handleCloseClick}
            style={{ color: "#333" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: "16px" }}>
          <div style={{ fontStyle: "italic", marginBottom: "8px" }}>
            Added at: {new Date(cartAddTime).toLocaleTimeString()}
          </div>

          <div>
            {operators?.length ? (
              operators.map((operator) => (
                <div
                  key={operator.operator_treatment_event_id}
                  style={{
                    marginBottom: "8px",
                    padding: "8px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                >
                  <strong>
                    Please choose how to handle the operator:
                  </strong>
                  <div style={{ marginTop: "8px" }}>
                    {actionsMap[operator.operator_treatment_event_id]?.map((action) => (
                      <button
                        key={action}
                        onClick={(e) =>
                        {
                          e.preventDefault();
                          e.stopPropagation();                          
                          handleSolveOperator(action, operator)}
                        }
                        style={{
                          margin: "4px",
                          padding: "8px 12px",
                          backgroundColor: "var(--carteav)",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        {actionsTitleMap[action] ? actionsTitleMap[action] : action}
                      </button>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No operators currently</p>
            )}
          </div>

          {/* Camera Carousel */}
          {/* <CartCamerasCarousel cartId={cart_number} /> */}
          
          <CamerasGrid cartId={cart_number} />


          <div 
          className="draggable-cart-action-buttons"
          style={{
            cursor: "move",
          }}>
            <CartActionButtons cartInfo={operatorCart} 
            onFocusCB={(e)=>{    
              setEventsContainerOpen(false);
              handleCloseClick(e);
              }} />
          </div>
       
        </div>
      </div>
    </Draggable>
  );
}
