import React, { useEffect,useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import stylesModule from "./event.module.css";
import { Button } from '@mui/material';
import { ReactComponent as HighIcon } from "../../../assets/icons/event/high.svg";
import { ReactComponent as MidIcon } from "../../../assets/icons/event/mid.svg";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import Swal from 'sweetalert2';
import useAuth from '../../../hooks/Auth/useAuth';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  


function MalfunctionEventDialog({data,openDialog,closeDialogFunction}) {

    const [dataMap,setDataMap] = useState(new Map());
    const [activeKey,setActiveKey] = useState("");
    const [operatorTreatmentEventsList,setOperatorTreatmentEventsList] = useState([]);



    useEffect(()=>{
        const malfunctions = data?.data?.malfunction || data?.data;
        const operatorsList = malfunctions?.operator_treatment_events_list;
        // //fake data 
        // const operatorsList =  [
        //     {
        //         'operator_treatment_event_id': 'OPERATOR_TREATMENT_EVENT_COLLISION',
        //         'description': 'Simulator collision detected'
        //     },
        //     {
        //         'operator_treatment_event_id': 'OPERATOR_TREATMENT_EVENT_COLLISION',
        //         'description': 'Simulator collision detected'
        //     },{
        //         'operator_treatment_event_id': 'OPERATOR_TREATMENT_EVENT_COLLISION',
        //         'description': 'Simulator collision detected'
        //     },{
        //         'operator_treatment_event_id': 'OPERATOR_TREATMENT_EVENT_COLLISION',
        //         'description': 'Simulator collision detected'
        //     }
        // ]
        if(Array.isArray(operatorsList)){
           // Check if operatorsList has changed
            if (operatorsList && JSON.stringify(operatorsList) !== JSON.stringify(operatorTreatmentEventsList)) {
                setOperatorTreatmentEventsList(operatorsList);
                console.log("SET OPERATORS LIST")
            }
        }
    },[data,operatorTreatmentEventsList])

    useEffect(()=>{
        //build the map (object -> malfunction array )
        const subjectMap = new Map();
        const malfunctions = data?.data?.malfunction || data?.data;
        
        if(malfunctions){
            
            const errors = malfunctions?.errors_list;
            const warnings = malfunctions?.warnings_list;
           
            if(Array.isArray(errors)){
                for (const error of errors){
                    
                    const subject  = error.subject;
                    error.type = 'error';
                    let existSubjectArray = subjectMap.get(subject);
                    if(existSubjectArray !== undefined){
                        existSubjectArray.push(error);
                        subjectMap.set(subject,existSubjectArray);
                    }else{
                        subjectMap.set(subject,[error]);
                    }
                }
            }
            if(Array.isArray(warnings)){
                for (const warning of warnings){
                    const subject  = warning.subject;
                    warning.type = 'warning';
                    let existSubjectArray = subjectMap.get(subject);
                    if(existSubjectArray){
                        existSubjectArray.push(warning);
                        subjectMap.set(subject,existSubjectArray);
                    }else{
                        subjectMap.set(subject,[warning]);
                    }
                }
            }
        }

        setDataMap(subjectMap);
    },[data,operatorTreatmentEventsList])

    const spreadedObject = { ...Object.fromEntries(dataMap) };
    const spreadActiveList = dataMap.get(activeKey);
    let {relevantUrl} = useAuth();

    const cancelOperatorTreatmentEvent = (p_cartID, p_eventNameList, p_isOnLine) => {

        
        if(p_isOnLine){
            axios.post(relevantUrl + '/admin/operator_treatment_cancel_event', 
                {
                    cart_id: p_cartID,
                    eventNameList: p_eventNameList
                })
            .then(res =>{
                if(res.data === 'ok')
                {
                    Swal.fire({
                        icon: 'success',
                        title: 'Operator treatment cancel secceed!',
                        position:'center',
                        confirmButtonColor:'#00A7EE',

                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title:'Something went wrong - ' + res.data,
                        position:'center',
                        confirmButtonColor:'#00A7EE',

                    })
                }
            })
        }
        else{
            Swal.fire({
                icon: 'warning',
                title:'Cart must be online !',
                position:'center',
                confirmButtonColor:'#00A7EE',

            });
        }

        
    }



  return (
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={closeDialogFunction}
            fullWidth={true}
            className={stylesModule.dialogContainer}
            aria-labelledby="dialog-block-cart" 
            style={{
                fontWeight:'500',
            }}
          >
            <DialogTitle
                className={stylesModule.dialog_header}
            >
                Cart {data.cart_id}, Malfunction Event
                <div className={stylesModule.dialogCloeButton}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        size='small'
                        onClick={closeDialogFunction}
                        aria-label="close"
                        
                    >
                        <CloseIcon  size='small' />
                    </IconButton>
                </div>
            </DialogTitle>
            {/* <div>
                      
            </div>  */}
            
            <DialogContent className='black-scroll-bar-container'>
                <div 
                    className={stylesModule.dialog_body}
                >
               
                        <div className={stylesModule.malfunctionLeftButtons}>
                        
                            {dataMap.size >0 &&Object.entries(spreadedObject).map(([key, value]) => (
                                <Button 
                                    key={key} 
                                    variant='text' 
                                    sx={{color:activeKey ===key?null:'#070A0E'}} 
                                    onClick={()=>{
                                        setActiveKey(key);
                                    }}
                                >
                                    {key}
                                </Button>
                            ))}
                        </div>
                        <div 
                            className={stylesModule.malfunctionActiveBody}
                        >

                            {
                                Array.isArray(spreadActiveList) && spreadActiveList.map((item,index)=>(
                                    <div
                                        key={`active-list-${index}-${item.name}`} 
                                        className={stylesModule.malfunctionActiveItemContainer}
                                    >
                                        <span style={{display:'flex',alignItems:'center'}}>{item.type === 'error'? <><HighIcon /> </>:<><MidIcon/></>}</span>
                                        <span>Subject: <span className={stylesModule.malfunctionItemText}>{item.subject}</span></span>
                                        <span>Name: <span className={stylesModule.malfunctionItemText}>{item.name}</span></span>
                                        <span>Tip: <span className={stylesModule.malfunctionItemText}>{item.tip}</span></span>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {operatorTreatmentEventsList.length >0 && 
                        <div className={stylesModule.dialogOperatorListActions}>
                            <div>
                                Cart is stopped due to some reason.<br/>
                                Click to release cart. 
                                <span  
                                    style={{cursor:'pointer',color: 'var(--carteav)',marginLeft:'12px'}} 
                                    onClick={()=>{cancelOperatorTreatmentEvent(data.cart_id, ['OPERATOR_TREATMENT_EVENT_COLLISION'], true);}}

                                >  
                                    Realease
                                </span>
                                <br/>
                            
                                <div style={{color:'var(--black)',marginTop:'8px'}}>
                                    Reasons:
                                </div>
                                <div style={{color:'var(--grey)',paddingLeft:'8px'}}>
                                    {operatorTreatmentEventsList.map((item,index)=>(
                                        <div key={`operatorTreatmentEventsList-item-${index}`} style={{marginTop:'2px'}}>
                                            {index+1}. {item.description? item.description :null}.
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
            </DialogContent>
          </Dialog>
        
  )
}

export default MalfunctionEventDialog