import React, { useState } from "react";
import styles from "./event.module.css"; // reuse the event.module.css for styling (or create your own)

export default function OperatorItem({
  operatorCart,  
  operator,  
  onClick,
  descriptionsMap
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { cart_number, operators, cartAddTime } = operatorCart;

  const handleToggle = () => {
    onClick(operatorCart,operator);
  };

  return (
    <div onClick={handleToggle}>
        <div className={styles.operator_event_item_close}>
          <span className={styles.operator_event_item_close_title}>
            Cart {cart_number} - {descriptionsMap[operator.operator_treatment_event_id]}
          </span>
          <span className={styles.operator_event_item_close_time}>
            {new Date(cartAddTime).toLocaleTimeString()}
          </span>
        </div>
    </div>
  );
}
