import { createContext, useEffect , useState } from "react";
import RefreshToken from "../../hooks/Auth/refreshToken";
import axios from "axios";
import { getTimeZoneFromAllSites } from "../../services/getTimeZoneFromAllsItes";
import { getMapDataObjectFromAllSites } from "../../services/getMapDataObjectFromAllSites";
import { GetBounderiesStorage, GetRoadsStorage, GetPOIS, GetCategories, GetMemos } from "../../services/getAllMapData"; 
import useStateAndRef from "../../hooks/useStateAndRef"; 

const AuthContext = createContext({});

export const AuthProvider = ({ children  }) => {
      
    const [auth, setAuth] = useState({});
    const [role,setRole] = useState(null);
    const [loading,setLoading] = useState(true);
    const [relevantUrl,setRelevantUrl] = useState(null);
    const [relevantSite,setRelevantSite] = useState(null);
    const [sitesMapsDataObject,setSitesMapsDataObject]=useState(null);
    const [relevantMapData , setRelevantMapData] = useState(null);

    const [sitesList,setSiteList] =useState(null);
    const [sitesSockectsConnection ,setSitesSockectsConnection]=useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [configData, setConfigData] = useState({}); 

        // Additional states for fetched data (moved from SiteOverview)
    const [allExistsCarts, setAllExistsCarts] = useState(null);
    const [cartsLocationsArr, setCartsLocationsArr] = useState(null);
    const [cartsPaths, setCartsPaths] = useState(null);
    const [cartsData, setCartsData] = useState(null);
    const [statusCounters, setStatusCounters] = useState(null);

      const [cartsLocationObject, setCartsLocationObject] = useState(null);
      const [onlineCarts, setOnlineCarts] = useState([]);
      const [filterParam, setFilterParam, refFilterParam] = useStateAndRef([]);
      const [cartToShowPath, setCartToShowPath, refCartToShowPath] = useStateAndRef([]);
      const [isPhoneSize, setIsPhoneSize] = useState(window.innerWidth < 768);
      const [eventsContainerOpen, setEventsContainerOpen] = useState(window.innerWidth > 768)

    // bounderiesStorageResponse,
    // roadsResponse,
    // POISResponse,
    // CategoriesResponse,
    // memosResponse,

    const [bounderiesStorage, setBounderiesStorage] = useState(null);
    const [roadsStorage, setRoadsStorage] = useState(null);
    const [POISStorage, setPOISStorage] = useState(null);
    const [CategoriesStorage, setCategoriesStorage] = useState(null);
    const [memosStorage, setMemosStorage] = useState(null);

    useEffect(() => {
      const handleResize = () => {
        const newWidth = window.innerWidth;
        setWindowWidth(newWidth);
        setIsPhoneSize(newWidth < 768);

      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    //eventObject = {
    //     "develop":[eventsList],
    //     "company":[eventsList]
    // }

    //mapDataObject = {
    //     "develop":[mapDataObject],
    //     "company":[mapDataObject]
    // }


    useEffect( ()=>{
        const getToken = async ()=>{
            try {
                const response = await RefreshToken();
                if(response){
                     setAuth(response);
                }
                 setLoading(false);
                 console.log("finish refresh token")
                } catch (error) {}
        }
        getToken();
    },[])

    useEffect(()=>{
        let url;
        
        // when user change site , update the relvant states 
        if(sitesMapsDataObject && relevantSite && Object.prototype.hasOwnProperty.call(sitesMapsDataObject, relevantSite)){
            setRelevantMapData(sitesMapsDataObject[relevantSite]);
        }

        if(relevantSite !== 'localhost') {
            url = "https://" + relevantSite+'.carteav.com';
        }else{
            url = "http://localhost:8080";
        }
        setRelevantUrl(url);

    },[relevantSite,sitesMapsDataObject]);

    useEffect(()=>{
        let intervalId ;
        
        if(auth && Object.keys(auth).length>0){
            let developmentAuth = auth;

            // only for development
            if(window.location.href.includes('localhost')){
                developmentAuth.relevant_site= 'localhost'
                // ,//'company'
                developmentAuth.sites_list = ['localhost'];
            }
            
            let relevantUrlDevelopment="";
            if(developmentAuth.relevant_site !== 'localhost' && developmentAuth.relevant_site){
                // production
                relevantUrlDevelopment = `https://${developmentAuth.relevant_site}.carteav.com`;
            }else{
                // only for development
                relevantUrlDevelopment = 'http://localhost:8080';
            }

            setSiteList(developmentAuth.sites_list);
            let currentRelevantSite = relevantSite;

            if(relevantSite == null ){
                currentRelevantSite =developmentAuth.relevant_site; 
                setRelevantSite(developmentAuth.relevant_site);
            }
            setRelevantUrl(relevantUrlDevelopment);
            setAuth(developmentAuth);
            setRole(auth.roles);


            setMapDataFromAllSitesFunction(developmentAuth.sites_list , currentRelevantSite);
        }

        return () => clearInterval(intervalId); 

    },[auth, relevantSite])

    const setMapDataFromAllSitesFunction = async (site_list) => {
        getMapDataObjectFromAllSites(site_list,setSitesMapsDataObject);
    };

    const fetchConfigData = async () => {
      try {
        const response = await axios.post(relevantUrl + '/admin/get_config_json');
        setConfigData(response.data);
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    const getConfigValue = (key) => {
      for (const section in configData) {
        const paramsArray = configData[section];
        
        if (Array.isArray(paramsArray)) {
          for (const param of paramsArray) {
            if (param.name === key) {
              return param.value;  
            }
          }
        }
      }
      return null;
    };
  
  useEffect(() => {
    if (relevantUrl) {
      fetchConfigData();
    }
  }, [relevantUrl]);


  const onReload = async (p_baseUrl) => {
    try {
      const [
        bounderiesStorageResponse,
        roadsResponse,
        POISResponse,
        CategoriesResponse,
        memosResponse,
      ] = await Promise.all([
        GetBounderiesStorage(p_baseUrl),
        GetRoadsStorage(p_baseUrl),
        GetPOIS(p_baseUrl),
        GetCategories(p_baseUrl),
        GetMemos(p_baseUrl),
      ]);

      setBounderiesStorage(bounderiesStorageResponse);
      setRoadsStorage(roadsResponse);
      setPOISStorage(POISResponse);
      setCategoriesStorage(CategoriesResponse);
      setMemosStorage(memosResponse);
    } catch (error) {
      console.error("onReload error:", error);
    }
  };

  const filterCarts = (p_param = filterParam, p_cartsDataArray = cartsData) => {
    if (Array.isArray(p_cartsDataArray)) {
      const filteredCartsArray = p_cartsDataArray.filter((cart) =>
        p_param.includes(cart.status)
      );
      setCartsData(filteredCartsArray);
    }
    return null;
  };


  const reloadCartsData = async () => {
    if (!relevantUrl) return;
    try {
      const result = await axios.get(`${relevantUrl}/admin/getAllCartsData`);
      setStatusCounters(result.data?.counterStauses);

      // 6a) Take the raw response
      const cartsDataResponse = result.data?.cartDataCurrent;

      // 6b) If we have filterParam, apply filterCarts; otherwise just set
      if (cartsDataResponse && refFilterParam.current.length > 0) {
        if (filterParam.length > 0) {
          filterCarts(filterParam, cartsDataResponse);
        } else {
          setCartsData(cartsDataResponse);
        }
      }

      // 6c) Also set onlineCarts
      if (cartsDataResponse) {
        const newOnlineCarts = cartsDataResponse.filter((cart) => cart.isOnLine);
        setOnlineCarts(newOnlineCarts);
      } else {
        setOnlineCarts([]);
      }

      // 6d) Update cartsLocationObject
      setCartsLocationObject((prevCartsLocation) => {
        const newCartsLocation = result.data?.realTimelocationCartsObject;
        if (JSON.stringify(prevCartsLocation) !== JSON.stringify(newCartsLocation)) {
          return newCartsLocation;
        }
        return prevCartsLocation;
      });

      // 6e) Update allExistsCarts (offline carts?), cartsLocationsArr
      setAllExistsCarts((prevAllExistsCarts) => {
        const newAllExistsCarts = result.data?.parsedOfflineArr;
        if (JSON.stringify(prevAllExistsCarts) !== JSON.stringify(newAllExistsCarts)) {
          return newAllExistsCarts;
        }
        return prevAllExistsCarts;
      });

      setCartsLocationsArr((prevCartsLocationsArr) => {
        const newCartsLocationsArr = result.data?.cartsLocationsArr;
        if (
          JSON.stringify(prevCartsLocationsArr) !==
          JSON.stringify(newCartsLocationsArr)
        ) {
          return newCartsLocationsArr;
        }
        return prevCartsLocationsArr;
      });
    } catch (err) {
      console.log("reloadCartsData error:", err);
      setCartsData(null);
      setOnlineCarts([]);
      setCartsLocationObject(null);
      setAllExistsCarts(null);
      setCartsLocationsArr(null);
    }
  };


 const reloadReservation = async () => {
  if (!relevantUrl) return;
  try {
    const res = await axios.get(`${relevantUrl}/admin/getAllFilteredActiveMissions`);
    setCartsPaths(res.data || {});

    if (res.data) {
      // Convert the keys of the returned object to an array
      let currentCartsPaths = Object.keys(res.data);

      // If we have a ref for cartToShowPath, filter out carts that no longer exist
      if (refCartToShowPath.current) {
        let cartToShowPathFiltered = refCartToShowPath.current.filter((cart) =>
          currentCartsPaths.includes(cart.toString())
        );
        setCartToShowPath(cartToShowPathFiltered);
      }
    } else {
      // If there's no data, and we previously had carts in cartToShowPath, clear them
      if (cartToShowPath.length > 0) {
        setCartToShowPath([]);
      }
    }
  } catch (err) {
    console.log("reloadReservation error:", err);
    setCartsPaths(null);

    // If an error occurs, optionally clear cartToShowPath
    setCartToShowPath([]);
  }
};



  useEffect(() => {
    if (!relevantUrl) return;

    // Immediately call onReload once
    onReload(relevantUrl);

    // Clear some states
    setAllExistsCarts(null);
    setCartsLocationsArr(null);

    // Setup intervals
    const intervalId1 = setInterval(() => {
      reloadCartsData();
    }, 1000);

    const intervalId2 = setInterval(() => {
      reloadReservation();
    }, 1000);

    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    };
  }, [relevantUrl]);


    if(!loading){
    return (
        <AuthContext.Provider 
            value={{
                relevantMapData, 
                setRelevantMapData, 
                setSitesMapsDataObject, 
                role,
                auth, 
                setAuth,
                relevantUrl,
                setRelevantUrl,
                sitesList,
                sitesSockectsConnection,
                setSitesSockectsConnection,
                relevantSite,
                setRelevantSite,
                sitesMapsDataObject,
                windowWidth,
                isPhoneSize, 

                getConfigValue,

                cartsData,
                statusCounters,
                allExistsCarts,
                cartsLocationsArr,
                cartsPaths,
                cartToShowPath,
                setCartToShowPath,
                refCartToShowPath,
                onReload,
                reloadCartsData,
                reloadReservation,
                onlineCarts, // NEW
                filterParam, // NEW
                refFilterParam,
                setFilterParam, // NEW
                cartsLocationObject, // NEW
                filterCarts, // NEW


                bounderiesStorage,
                roadsStorage,
                POISStorage,
                CategoriesStorage,
                memosStorage,


            }}
        >
            {children}
        </AuthContext.Provider>
    )
    }else{
        return null;
    }
}

export default AuthContext;