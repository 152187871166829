// OperatorList.jsx (or whatever your parent is)
import React, { useState } from "react";
import OperatorItem from "./OperatorItem";
import DraggableOperatorItem from "./DraggableOperatorItem";
import ModalOperatorItem from "./ModalOperatorItem";
import common_types from "../../../common_files/common_types";
import useAuth from "../../../hooks/Auth/useAuth";

export default function OperatorList({ operatorsArr,setEventsContainerOpen }) {
const { isPhoneSize } = useAuth();  


//common_types.operations_enum.OPERATION_BYPASS_RIGHT,
// common_types.operations_enum.OPERATION_BYPASS_LEFT,
// common_types.operations_enum.OPERATION_BYPASS_IGNORE,
// Create the maps
const actionsMap = {
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_JUNCTION]: [
    common_types.operations_enum.OPERATION_WAIT,
    common_types.operations_enum.OPERATION_DRIVE
  ],
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_COLLISION]: [
    common_types.operations_enum.OPERATION_WAIT,
    common_types.operations_enum.OPERATION_DRIVE,
  ],
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_SM_EBRAKE]: [
    common_types.operations_enum.OPERATION_WAIT,
    common_types.operations_enum.OPERATION_DRIVE,

  ],
};

const actionsTitleMap = {
  [common_types.operations_enum.OPERATION_WAIT]: "Wait",
  [common_types.operations_enum.OPERATION_DRIVE]: "Drive",
  [common_types.operations_enum.OPERATION_BYPASS_RIGHT]: "Right Bypass",
  [common_types.operations_enum.OPERATION_BYPASS_LEFT]: "Left Bypass",
  [common_types.operations_enum.OPERATION_BYPASS_IGNORE]: "Continue Strait"
};

const descriptionsMap = {
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_JUNCTION]:
    "junction scenario",
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_COLLISION]:
    "potential collision",
  [common_types.operator_treatment_event_id.OPERATOR_TREATMENT_EVENT_SM_EBRAKE]:
    "sm break",
};
  const [openCart, setOpenCart] = useState(null);
  const [activeop, setActiveop] = useState(null);

  const handleItemClick = (operatorCart,operator) => {
    // This opens the DraggableOperatorItem for that cart
    setOpenCart(operatorCart);
    setActiveop(operator);
  };

  const handleCloseOperatorItem = () => {
    // Closes the DraggableOperatorItem
    setOpenCart(null);
    setActiveop(null);
  };

  return (
    <>
      {operatorsArr?.length ? (
        operatorsArr.map((cart) => (
          cart.operators.map((operator) => 
          <OperatorItem
            key={cart.cart_number}
            operatorCart={cart}
            operator={operator}
            onClick={handleItemClick}
            descriptionsMap={descriptionsMap}
          />
          )
        ))
      ) : (
        <p>No operator carts available</p>
      )}

      {/* Conditionally render the draggable details if openCart is set */}
      {openCart && (
        <>
          {!isPhoneSize ? (
            <DraggableOperatorItem
              operatorCart={openCart}
              activeop={activeop}
              onClose={handleCloseOperatorItem}
              descriptionsMap={descriptionsMap}
              actionsMap={actionsMap}
              actionsTitleMap={actionsTitleMap}
              setEventsContainerOpen={setEventsContainerOpen}

            />
          ) : (
            <ModalOperatorItem
              operatorCart={openCart}
              activeop={activeop}
              onClose={handleCloseOperatorItem}
              descriptionsMap={descriptionsMap}
              actionsMap={actionsMap}
              actionsTitleMap={actionsTitleMap}
              setEventsContainerOpen={setEventsContainerOpen}
            />
          )}
        </>
      )}
    </>
  );
}
