import axios from "axios";


  const SendOperatorTreatmentResponseEvent =  async (cartId, eventName, operation,releventUrl) => {

    try {
      const response = await axios.post(`${releventUrl}/admin/operator_treatment_response_event`, {
        cart_id: cartId,
        eventName,
        operation,
      });
      return response.data;
    } catch (error) {
      console.error("Error sending operator treatment response:", error);
      throw error;
    }
  }

export default SendOperatorTreatmentResponseEvent;
