import axios from "axios";


export  async function GetMapdata(p_baseUrl){
    try {
        let mapDataResponse = await axios.get(p_baseUrl +'/admin/get_map_data');
            if(mapDataResponse.status === 200){
                return mapDataResponse.data;
            }
            return 'error';
    } catch (error) {
        console.log("error While trying to GetMapdata: " ,error);
        return 'error';
    }

}
export  async function GetBounderiesStorage(p_baseUrl){
    try {

        let boundariesResponse = await  axios.post(p_baseUrl + '/admin/getBounderiesStorage');
        if(boundariesResponse.status === 200){

            let boundariesArray = {};
            Object.entries(boundariesResponse.data).forEach(([key, value]) => {
                const localArr =(JSON.parse((value.geometry)).coordinates);
                const NewAraay=[];
                localArr.map((boundaryArray,index)=>(
                    NewAraay.push(boundaryArray)
                ));
                boundariesArray[value.id] = NewAraay;
            });
            return boundariesArray;
        }else{
            return 'error';
        }
 
    } catch (error) {
        console.log("error While trying to GetBounderiesStorage: " ,error);
        return 'error';
    }

}


export  async function GetRoadsStorage(p_baseUrl){
    try {
        let roadsResponse =await axios.post(p_baseUrl + '/admin/getRoadsStorage');
        if(roadsResponse.status === 200){
            let roadsArray = {};
            for(let key of roadsResponse.data) {
                let coordinates = JSON.parse(key.geometry);
                if(coordinates && coordinates.coordinates){
                    roadsArray[key.id]=coordinates.coordinates;
                }
            }
            return roadsArray;
        }else{
            return 'error';
        }
 
    } catch (error) {
        console.log("error While trying to GetRoadsStorage: " ,error);
        return 'error';
    }
}

export  async function GetPOIS(p_baseUrl){
    try {
         let poisResponse = await axios.post(p_baseUrl + '/admin/getPOIStorage');
            if(poisResponse.status === 200){
                return poisResponse.data;
            }
            return 'error';
    } catch (error) {
        console.log("error While trying to GetPOIStorage: " ,error);
        return 'error';
    }

}
export  async function GetCategories(p_baseUrl){
    try {
         let categoriesResponse = await axios.post(p_baseUrl + '/admin/getCategories');
            if(categoriesResponse.status === 200){
                return categoriesResponse.data;
            }
            return 'error';
    } catch (error) {
        console.log("error While trying to GetPOIStorage: " ,error);
        return 'error';
    }

}



export  async function GetMemos(p_baseUrl){
    try {
        let memosResponse = await axios.post(p_baseUrl + '/admin/getMemoStorage');
        
            if(memosResponse.status === 200){
                return memosResponse.data;
            }
            return 'error';
    } catch (error) {
        console.log("error While trying to GetMapdata: " ,error);
        return 'error';
    }

}


// const  = async (p_baseUrl)=>{

   
//     try {
       
//        

//         


//         console.log("response: " );
//         // return ({
//         //         mapDataResponse:mapDataResponse,boundariesArray:boundariesArray,roadsArray:roadsArray,poisResponse:poisResponse,memosResponse:memosResponse});

//     } catch (error) {
    
//     }

    
    



// }

// export default getMapdata;