import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircleIcon from '@mui/icons-material/Circle';
import PauseIcon from "@mui/icons-material/Pause";
import uploadTemporaryAudio from "../../services/uploadTemporaryAudio";
import renderCustomModalFunction from "../customModal/modalWrapper/renderCustomModalFunction";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

const AudioRecorderModal = ({ baseUrl, onClose, cartNumber }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [recordingData, setRecordingData] = useState(null);

  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);
  const recordPlugin = useRef(null);
  const totalDuration = 30; // 30 seconds limit for recording
  const timerRef = useRef(null); // Define the timerRef using useRef
  const autoStopRef = useRef(null); // Reference for auto-stop

  useEffect(() => {
    if (!waveformRef.current) return;

    // Create WaveSurfer instance
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      progressColor: "#009ddb",
      waveColor: '#009ddb',
      cursorColor: '#00a7ee33',
      height: 60,

      "cursorWidth": 0,
      "barWidth": 3,

    });

    // Register the Record plugin
    recordPlugin.current = waveSurfer.current.registerPlugin(
      RecordPlugin.create({
        renderRecordedAudio: true,
      })
    );

    // Handle recording end event
    recordPlugin.current.on("record-end", (blob) => {
      const recordedUrl = URL.createObjectURL(blob);
      setAudioURL(recordedUrl);
      setRecordingData(blob);
    });

    return () => {
      if (waveSurfer.current) {
        waveSurfer.current.destroy();
      }
    };
  }, []);

  const stopRecording = () => {
    recordPlugin.current.stopRecording(); // Stop recording regardless of state
    setIsRecording(false);
    clearInterval(timerRef.current); // Clear the progress timer
    clearTimeout(autoStopRef.current); // Clear the auto-stop timeout
  };
  
  
  const startRecording = () => {
    setIsRecording(true);
    setProgress(0);
    setUploadMessage(null);

    recordPlugin.current.startRecording();
    updateProgress();
  
    // Automatically stop recording after totalDuration (30 seconds)
    autoStopRef.current = setTimeout(() => {
      setUploadMessage("Recording is up to 30 sec' ")
      stopRecording(); // Automatically stop after 30 seconds
    }, totalDuration * 1000);
  };
  



const updateProgress = () => {
  timerRef.current = setInterval(() => {
    setProgress((prev) => {
      if (prev < 100) {
        return prev + (100 / totalDuration);
      } else {
        clearInterval(timerRef.current); // Clear progress interval
        stopRecording(); // Directly stop recording when progress reaches 100%
        return 100;
      }
    });
  }, 1000); // Progress updates every second
};


  const uploadRecordedFile = async () => {
    if (!recordingData) return;
    setIsUploading(true);
    setUploadMessage(null);

    try {
      const result = await uploadTemporaryAudio(baseUrl, cartNumber, recordingData);
      setUploadMessage(result === "error" ? "Failed send to cart audio file." : "Audio sended to cart successfully!");
    } catch (error) {
      setUploadMessage("Error send to cart audio file.");
    } finally {
      setIsUploading(false);
    }
  };

  const confirmUpload = async () => {
    const options = {
      title: "Confirm Send",
      bodyText: "Are you sure you want to send this audio to the cart?",
      buttons: [
        {
          text: "Cancel",
          class: "cancelButton",
          closeModal: true,
          value: "cancel",
        },
        {
          text: "Confirm",
          class: "dangerButton",
          closeModal: true,
          callback: uploadRecordedFile,
          value: "confirm",
        },
      ],
    };
    await renderCustomModalFunction(options);
  };

  // Styled button component
  const StyledButton = ({ label, onClick, className, disabled }) => (
    <div
      onClick={!disabled ? onClick : null}
      className={`customModalButton ${className}`}
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 24px",
        borderRadius: "20px",
        fontFamily: "Arial, sans-serif",
        fontSize: "0.9rem",
        fontWeight: 600,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {label}
    </div>
  );

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: 2 }}>
      {/* Title on the top left */}
      <Typography variant="h6" sx={{ mb: 2, textAlign: "left" }}>
        Record Audio
      </Typography>
  {/* Row with Circular Progress Bar and WaveSurfer.js visualization */}
  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
    {/* Circular Progress Bar with Play/Pause button */}
    <Box sx={{ width: 60, height: 60, display: "flex", mr: 4, alignItems:"center" }}>
      <CircularProgressbarWithChildren
        value={progress}
        styles={buildStyles({
          pathColor: "#00b0ff",
          trailColor: "#d6d6d6",
        })}
      >
        <div onClick={isRecording ? stopRecording : startRecording}>
          {isRecording ? <PauseIcon sx={{ color: "#00b0ff" }} /> : <CircleIcon style={{width:24, height:24,}} sx={{ color: "#ff0000" }} />}
        </div>
      </CircularProgressbarWithChildren>
    </Box>

    {/* WaveSurfer.js visualization */}
    <Box sx={{ flex: 1 }}>
      <div
        ref={waveformRef}
        style={{
          height: "60px",
          backgroundColor: "#f0f0f0",
          borderRadius: "40px",
        }}
      />
    </Box>
  </Box>


      {/* Row with progress bar and audio player */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", mb: 4 }}>

          {/* Audio Preview */}
          <Box sx={{ flex: 1 }}>
            <Typography 
            sx={{
              fontFamily: "Arial, sans-serif",
              fontSize: "0.8rem",
              fontWeight: 700,
              color: !audioURL ? "gray" : "black"
            
            }}
            >
              Preview</Typography>
            <audio controls src={audioURL} disabled={!audioURL} style={{width:'100%'}}/>
          </Box>

      </Box>

      {/* Bottom Row with Cancel and Send buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <StyledButton label="Cancel" onClick={onClose} className="cancelButton" disabled={false} />
        <StyledButton label={isUploading ? "Sending..." : "Send"} onClick={confirmUpload} className="dangerButton" disabled={isUploading || !audioURL} />
      </Box>

      {/* Display the server message */}
      {uploadMessage && (
        <Typography variant="body2" color={uploadMessage.includes("successfully") ? "green" : "red"} sx={{ mt: 2 }}>
          {uploadMessage}
        </Typography>
      )}
    </Box>
  );
};

export default AudioRecorderModal;
