import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import useAuth from "../../hooks/Auth/useAuth";
import axios from "axios";
import Swal from "sweetalert2";

const camerasList = [
  { camera_name: "left", show: true },
  { camera_name: "front", show: true },
  { camera_name: "right", show: true },
  { camera_name: "incabin", show: true },
];

export default function CamerasGrid({ cartId }) {
  const { relevantUrl, relevantSite, sitesSockectsConnection, auth } = useAuth();
  const [cameraImages, setCameraImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSocketData = (data) => {
    const { cameras_data_list } = JSON.parse(data);

    if (cameras_data_list && Array.isArray(cameras_data_list)) {
      const images = cameras_data_list
        .filter((camera) => camera.image_base64)
        .map((camera) => ({
          name: camera.camera_name,
          image: `data:image/jpeg;base64,${camera.image_base64}`,
        }));

      setCameraImages(images);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (relevantSite && sitesSockectsConnection && sitesSockectsConnection[relevantSite]) {
      const relevantSiteSocket = sitesSockectsConnection[relevantSite];
      relevantSiteSocket.on("cartCamerasData", handleSocketData);
      return () => {
        relevantSiteSocket.emit("sm_turn_off_cameras_event", cartId);
        relevantSiteSocket.off("cartCamerasData");
      };
    }
  }, [sitesSockectsConnection, relevantSite]);

  useEffect(() => {
    const requestCameras = async () => {
      try {
        const cameraCommandsList = camerasList.map((camera) => ({
          camera_name: camera.camera_name,
          show: camera.show,
        }));

        const response = await axios.post(`${relevantUrl}/admin/requestCameras`, {
          camera_commands_list: cameraCommandsList,
          cartId,
          adminId: auth?.userId || null,
        });

        if (response.status !== 200) {
          Swal.fire({
            icon: "error",
            title: "Failed to request cameras",
            text: response.data,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
    };

    if (relevantUrl) {
      setLoading(true);
      requestCameras();
    }
  }, [relevantUrl, cartId, auth]);

  return (
    <Box style={{ width: "100%", margin: "auto", marginBottom: "40px" }}>
      {loading ? (
        <CircularProgress />
      ) : cameraImages.length > 0 ? (
        <Box
          display="flex"
          flexWrap="nowrap" // Ensures all items are in one row
          style={{
            gap: "16px",
            padding: "16px",
            justifyContent: "space-between", // Ensures items spread evenly
            overflow: "hidden", // Prevents scrollbars
          }}
        >
          {cameraImages.map((camera, index) => (
            <Box
              key={index}
              style={{
                flex: "1 1 0", // Distributes items equally
                maxWidth: `${100/cameraImages.length}%`, // Ensures equal width for 4 items
                textAlign: "center",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {camera.name.charAt(0).toUpperCase() + camera.name.slice(1).toLowerCase()}
              </Typography>
              <img
                src={camera.image}
                alt={`Camera - ${camera.name}`}
                style={{
                  width: "100%",
                  objectFit: "contain",
                  borderRadius: "4px",
                }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" align="center">
          No camera images available
        </Typography>
      )}
    </Box>
  );
}
