import React, { useState, useEffect, useRef } from "react";
import styles from "./event.module.css";
import useAuth from "../../../hooks/Auth/useAuth.js";
import { ROLES } from "../../../redux/context/allowedTypes.js";
import SearchBar from "material-ui-search-bar";
import EventItem from "./eventItem.js";
import axios from "axios";
import VoiceCall from "../../vocieCall/voice.js";
import { useHistory } from "react-router-dom";
import BlockEventDialog from "./blockEventDialog";
import MalfunctionEventDialog from "./malfunctionEventDialog";
import OperatorItem from "./OperatorItem";
import errorSound from "./error_sound.mp3";
import OperatorList from "./OperatorList.js";
export default function Event({
  sitesEventObject,
  setSitesEventObject,
  eventsContainerOpen,
  setEventsContainerOpen,
  eventContainerRef,
}) {
  let { sitesSockectsConnection, relevant_site, role, auth, relevantUrl,cartsLocationsArr } = useAuth();
  let [openMalfuntionDialog, setOpenMalfuntionDialog] = useState(false);
  let [openDialog, setOpenDialog] = useState(false);
  const [malfunctionDialogData, setMalfunctionDialogData] = useState(null);
  let [assisCartEventObject, setAssistCartEventObject] = useState(null);
  const [eventSearchVal, setEventSearchVal] = useState("");
  const history = useHistory();

  const audioRef = useRef(null);
  const [isAudioUnlocked, setIsAudioUnlocked] = useState(false);
  const queuedSounds = useRef([]);
  useEffect(() => {
    // Create the Audio object
    audioRef.current = new Audio(errorSound);

    // Unlock audio playback for Safari and other strict browsers
    const unlockAudio = () => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      if (audioContext.state === "suspended") {
        audioContext.resume().then(() => {
          console.log("Audio context resumed");
        });
      }

      // Play queued sounds
      while (queuedSounds.current.length > 0) {
        const sound = queuedSounds.current.shift();
        sound.currentTime = 0;
        sound.play().catch((err) => {
          console.error("Error playing queued sound:", err);
        });
      }

      setIsAudioUnlocked(true);
      window.removeEventListener("click", unlockAudio);
      window.removeEventListener("touchstart", unlockAudio);
    };

    window.addEventListener("click", unlockAudio);
    window.addEventListener("touchstart", unlockAudio);

    return () => {
      window.removeEventListener("click", unlockAudio);
      window.removeEventListener("touchstart", unlockAudio);
    };
  }, []);

  const playErrorSound = () => {
    if (audioRef.current) {
      if (isAudioUnlocked) {
        audioRef.current.currentTime = 0; // Rewind to start
        audioRef.current.play().catch((err) => {
          console.error("Failed to play error sound:", err);
        });
      } else {
        // Queue the sound to be played later
        queuedSounds.current.push(audioRef.current);
      }
    }
  };
    /**
   * Each item in "operators" is shaped like:
   * {
   *   cart_number: "12",
   *   operators: [
   *     {
   *       operator_treatment_event_id: "...", // some unique ID
   *       description: "...",
   *       isNew: true/false,
   *       addTime: "2023-10-10T10:00:00Z"
   *     },
   *     ...
   *   ],
   *   cartAddTime: "2023-10-10T10:00:00Z"
   * }
   */
  const [cartOperators, setCartOperators] = useState([]);
  const [operators, setOperators] = useState([]);

  useEffect(() => {
    // 1) If no carts, clear existing operators
    if (!cartsLocationsArr?.length) {
      if (operators.length) setOperators([]);
      return;
    }

    // 2) Build a map of cart_number => current operator list
    const newMap = {};
    cartsLocationsArr.forEach((cart) => {
      if (Array.isArray(cart.operatorTreatmentList) && cart.operatorTreatmentList.length) {
        newMap[cart.cart_number] = cart.operatorTreatmentList;
      }
    });
    // If the map is empty but we still have operators, clear them
    if (!Object.keys(newMap).length) {
      if (operators.length) setOperators([]);
      return;
    }

    // 3) Build a dict from our existing operators state
    const oldDict = operators.reduce((acc, cartObj) => {
      acc[cartObj.cart_number] = cartObj;
      return acc;
    }, {});

    // 4) Construct the new operators array ("next")
    const next = Object.entries(newMap).reduce((arr, [cart_number, incOps]) => {
      const oldCart = oldDict[cart_number];

      // A) Brand-new cart => all operators are "new"
      if (!oldCart) {
        const newOperators = incOps.map((op) => {
          // ALERT for new operator
          console.log("NEW OPERATOR ALERT:", op.description);
          playErrorSound();

          return {
            operator_treatment_event_id: op.operator_treatment_event_id,
            description: op.description,
            isNew: true,
            addTime: new Date().toISOString(),
          };
        });
        arr.push({
          cart_number,
          operators: newOperators,
          cartAddTime: new Date().toISOString(),
        });
      } 
      // B) Existing cart => see which ops are new or updated
      else {
        const oldOpsMap = oldCart.operators.reduce((m, o) => {
          m[o.operator_treatment_event_id] = o;
          return m;
        }, {});
        const updatedOps = incOps.map((op) => {
          // If this operator_treatment_event_id already existed, keep it (maybe update description)
          if (oldOpsMap[op.operator_treatment_event_id]) {
            return { ...oldOpsMap[op.operator_treatment_event_id], description: op.description };
          }
          // Otherwise => brand-new operator
          console.log("NEW OPERATOR ALERT:", op.description);
          playErrorSound();

          return {
            operator_treatment_event_id: op.operator_treatment_event_id,
            description: op.description,
            isNew: true,
            addTime: new Date().toISOString(),
          };
        });

        if (updatedOps.length) {
          arr.push({
            cart_number,
            operators: updatedOps,
            cartAddTime: oldCart.cartAddTime,
          });
        }
      }
      return arr;
    }, []);

    // 5) For operators that have *disappeared*, mark them and remove after 5 seconds.
    //    We'll look for carts or operators that existed in "oldDict" but no longer appear in "newMap".
    Object.entries(oldDict).forEach(([oldCartNum, oldCartObj]) => {
      // (a) If the entire cart is missing
      if (!newMap[oldCartNum]) {
        oldCartObj.operators.forEach((removedOp) => {
          console.log("OPERATOR DISAPPEARED => mark it, remove after 5s:", removedOp.description);
          removeOperator(oldCartNum, removedOp.operator_treatment_event_id)
        });
      } 
      // (b) If only some operators are missing
      else {
        const incomingOps = newMap[oldCartNum];
        const missing = oldCartObj.operators.filter(
          (prevOp) =>
            !incomingOps.some(
              (incOp) => incOp.operator_treatment_event_id === prevOp.operator_treatment_event_id
            )
        );
        missing.forEach((removedOp) => {
          console.log("OPERATOR DISAPPEARED => mark it, remove after 5s:", removedOp.description);
          removeOperator(oldCartNum, removedOp.operator_treatment_event_id);
        });
      }
    });

    // 6) Compare "next" with our existing state
    const changed = JSON.stringify(operators) !== JSON.stringify(next);
    if (changed) setOperators(next);
  }, [cartsLocationsArr]);


  /**
   * Helper to remove an operator from our local state after 5s.
   * If that operator is the last one in the cart, remove the cart entirely.
   */
  const removeOperator = (cartNumber, operatorId) => {
    operators.forEach((op) => { if (op.operator.operator_treatment_event_id === operatorId) op.isNew = false; });
    setTimeout(() => {
    setOperators((curr) => {
      const cartIndex = curr.findIndex((c) => c.cart_number === cartNumber);
      if (cartIndex === -1) return curr;

      const oldCart = { ...curr[cartIndex] };
      const newOps = oldCart.operators.filter(
        (op) => op.operator_treatment_event_id !== operatorId
      );

      if (!newOps.length) {
        // Remove the entire cart
        return curr.filter((_, idx) => idx !== cartIndex);
      }
      // Otherwise, just update that cart's operators
      const updatedCart = { ...oldCart, operators: newOps };
      return curr.map((cart, idx) => (idx === cartIndex ? updatedCart : cart));
    });}, 10000);
  };

  const toggleEventsContainer = () => {
    setEventsContainerOpen((prevState) => !prevState);
  };

  const closeMalfunctionDialog = () => {
    setOpenMalfuntionDialog(false);
    setMalfunctionDialogData(null);
  };

  const requestEventSearch = (p_searchVal) => {};

  const cancelEventSearch = () => {
    setEventSearchVal("");
  };

  // in case of assist cart malfunction dialog open , should updae data in real time
  useEffect(() => {
    if (openMalfuntionDialog && sitesEventObject) {
      let updatedEvent = null;
      if (Object.keys(sitesEventObject).length > 0) {
        for (const eventArray of Object.values(sitesEventObject)) {
          for (const eventItem of eventArray) {
            if (eventItem.eventId === malfunctionDialogData.eventId) {
              updatedEvent = eventItem;
            }
          }
        }
      }

      if (updatedEvent) {
        setMalfunctionDialogData(updatedEvent);
      } else {
        setOpenMalfuntionDialog(false);
        setMalfunctionDialogData(null);
      }
    }
  }, [openMalfuntionDialog, sitesEventObject]);

  const assistCart = (cartId, eventId, eventName, blockedImg) => {
    const newAssistCart = {
      cartId: cartId,
      eventId: eventId,
      eventName: eventName,
      blockedImg: blockedImg,
    };

    setAssistCartEventObject(newAssistCart);
    setOpenDialog(true);
  };

  const showMalfunctionModal = (p_event) => {
    setOpenMalfuntionDialog(true);
    setMalfunctionDialogData(p_event);
  };

  const resolveEvent = async (p_eventId) => {
    // Find the category and event index
    let category, eventIndex;
    for (const key in sitesEventObject) {
      eventIndex = sitesEventObject[key].findIndex(
        (event) => event.eventId === p_eventId
      );
      if (eventIndex !== -1) {
        category = key;
        break;
      }
    }

    if (category !== undefined && eventIndex !== -1) {
      // Create a copy of the state
      const newSitesEventObject = { ...sitesEventObject };
      const [removedEvent] = newSitesEventObject[category].splice(
        eventIndex,
        1
      );

      // Update the state
      setSitesEventObject(newSitesEventObject);

      try {
        axios.post(relevantUrl + "/admin/deleteEvent", { eventId: p_eventId });
      } catch (error) {
        // If the request fails, add the event back to the sitesEventObject
        newSitesEventObject[category].splice(eventIndex, 0, removedEvent);
        setSitesEventObject(newSitesEventObject);
      }
    }
  };

  const removeEventFromList = (p_eventId) => {
    console.log(p_eventId);
    console.log(sitesEventObject);
  };

  const focusOnCart = (p_cartId) => {
    history.push({
      pathname: "/dashboard_site",
      state: { cartID: p_cartId },
    });
    return;
  };

  const callBack = (userId) => {
    if (sitesSockectsConnection && relevant_site) {
      const relevant_socket = sitesSockectsConnection[relevant_site];

      if (relevant_socket) {
        relevant_socket.emit("getUserDetails", userId);
      }
    } else {
      console.log(
        "Call Back failed ",
        sitesSockectsConnection,
        " ",
        relevant_site
      );
    }
  };

  return (
    <div>
      <VoiceCall />

      {auth &&
      auth.userName &&
      role &&
      role !== ROLES.SITE_MANAGER &&
      eventsContainerOpen ? (
        <div className={styles.events_alerts_body_open} ref={eventContainerRef}>
          {/* Events Section */}
          <div className={styles.split_container}>
            <div className={styles.events_section}>
              <div className={styles.cotainer_header}>
                <div
                  className={styles.center_row}
                  style={{ width: "100%", borderBottom: "2px solid #070A0E" }}
                >
                  Events
                  <span className={styles.events_number_circle}>
                    {sitesEventObject && sitesEventObject[auth.relevantSite]
                      ? sitesEventObject[auth.relevantSite].length
                      : 0}
                  </span>
                </div>
              </div>
              <div className={styles.search_bar}>
                <SearchBar
                  style={{
                    border:
                      eventSearchVal !== ""
                        ? "1px solid #00A7EE"
                        : "1px solid #CED5EF",
                    boxShadow: "none",
                    width: "100%",
                  }}
                  value={eventSearchVal}
                  onChange={(searchVal) => requestEventSearch(searchVal)}
                  onCancelSearch={cancelEventSearch}
                />
              </div>
              <div>
                {sitesEventObject && sitesEventObject[auth.relevant_site]
                  ? sitesEventObject[auth.relevant_site].map((event, index) => (
                    <EventItem
                      resolveEvent={resolveEvent}
                      key={`event-item-${event.event_name}-${index}`}
                      event={event}
                      focusOnCart={focusOnCart}
                      callBack={callBack}
                      assistCart={assistCart}
                      showMalfunctionModal={showMalfunctionModal}
                    />

                    ))
                  : null}
              </div>
            </div>

            {/* Operators Section */}
            <div className={styles.operators_section}>
              <div className={styles.cotainer_header}>
                <div
                  className={styles.center_row}
                  style={{ width: "100%", borderBottom: "2px solid #070A0E" }}
                >
                  Operators
                  <span className={styles.events_number_circle}>
                    {operators?.length || 0}
                  </span>
                </div>
              </div>
              <div className={styles.operator_placeholder} style={{ flexDirection: "column", alignItems: "center" }}>
                <OperatorList operatorsArr={operators} setEventsContainerOpen={setEventsContainerOpen} /> 
                
              </div>
            </div>

          </div>
        </div>
      ) : null}

      {/* Dialogs */}
      {assisCartEventObject && (
        <BlockEventDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          assisCartEventObject={assisCartEventObject}
          setAssistCartEventObject={setAssistCartEventObject}
        />
      )}
      {openMalfuntionDialog && (
        <MalfunctionEventDialog
          openDialog={openMalfuntionDialog}
          closeDialogFunction={() => setOpenMalfuntionDialog(false)}
          data={malfunctionDialogData}
        />
      )}
    </div>
  );
}
