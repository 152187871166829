import React from "react";
import styles from "./event.module.css";
import { ReactComponent as HighIcon } from "../../../assets/icons/event/high.svg";
import { ReactComponent as MidIcon } from "../../../assets/icons/event/mid.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/event/info.svg";
import { ReactComponent as AssistCart } from "../../../assets/icons/event/buttons/assist.svg";
import { ReactComponent as MissedCall } from "../../../assets/icons/event/missedCall.svg";
import BottomActions from "./bottomActions";
import common_types from "../../../common_files/common_types";

const EVENT_TYPES=common_types.event_type_enum;
const EVENT_SEVERITY_ENUM = common_types.event_severity_enum;


const errorString = EVENT_SEVERITY_ENUM.ERROR;
const warningString = EVENT_SEVERITY_ENUM.WARNING;
const infoString = EVENT_SEVERITY_ENUM.INFO;

const EventIconsEnum ={}
EventIconsEnum[errorString] = <HighIcon/>;
EventIconsEnum[warningString] = <MidIcon/>;
EventIconsEnum[infoString] = <InfoIcon/>;

const roadEventString = EVENT_TYPES.ROAD_EVENT;
const mulfunctionEventString = EVENT_TYPES.MULFUNCTION_EVENT;
const cartUnavailavilityEventString = EVENT_TYPES.CART_UNAVAILABILITY_EVENT;

const BodyTextEnum ={};
BodyTextEnum[roadEventString]=<div>The cart is blocked due to an obsacle.You can handle this by manually bypassing it.</div>;
BodyTextEnum[cartUnavailavilityEventString] = <div>Please ensure someone is contacting this user.</div>

//Road Block Event
    // event_type: 'road_event',
    // event_name: 'Road Event',
    // cart_id: p_cartObj.cart_number,
    // last_update_date_time: date,
    // open_date_time: new Date(),
    //blockedImg:img(base 64)


//Voice call Event data
    // userId: p_customer_id,
    // roomId: p_RoomId,
    // name:p_name,
    // event_type: 'voice_assistance',
    // event_name: 'Passanger call for assistance',
    // last_update_date_time: Date.now()


//missed_call
    // userId: userId,
    // name:userName,
    // event_type: 'missed_call',
    // event_name: 'Missed call',
    // counter:0,
    // open_date_time: new Date(),
    // last_update_date_time: Date.now()


//Mulfunction Event Data
    // event_type: 'cart_mulfunction_event',
    // event_name: 'Cart Mulfunction',
    // cart_id: cart_status.cart_number,
    // last_update_date_time: Date.now()


   

export default function EventItem({
        event,
        resolveEvent,
        focusOnCart,
        callBack,
        assistCart,
        showMalfunctionModal
    }){

    let [hover,setHover]=React.useState(false);
    let isMissedCalledEvent = event.event_type === EVENT_TYPES.MISSED_CALL_EVENT;
    let icon =isMissedCalledEvent ? <MissedCall/> :  EventIconsEnum[event.severity];
    let openTime =new Date(event.open_date_time);
    let eventId = event.eventId;
    let userId = event.user_id ? event.user_id : null;
    let minStr =(openTime.getMinutes()<10?'0':'') + openTime.getMinutes() ;
    let houStr = (openTime.getHours()<10?'0':'') + openTime.getHours() ;
    let blockedImg = event && event.blockedImg ? event.blockedImg : null;
    let data = event.data ? event.data : null;
    let bodyText= BodyTextEnum[event.event_type];


    if(data &&  event.event_type !== mulfunctionEventString) {
        if(Array.isArray(data)) {

            bodyText =<div>
                        {
                            data.map((reason,index)=>(
                                <p style={{fontSize:index === 0 ? "0.9rem":null,fontWeight:index ===0?"bold":null,paddingLeft:index === 0 ?0:12,margin:index === 0 ?"-12px 0 12px 0" :"3px 0"}} key={`${reason}-${index}`}>{reason}</p>
                            ))
                        }
                    </div>;
         
        }else{
            bodyText =<div>{data}</div>;
        }
    }
    if(data &&  event.event_type === mulfunctionEventString) {
        let errors ;
        let warnings ;

        if(Array.isArray(data.errors_list)) {

            errors =<div>
                        {
                            data.errors_list.map((item,index)=>(
                                <>
                                    <div  key={`${item.name}-errors_list-index`}>Title :{item.name}</div>
                                </>

                            ))
                        }
                    </div>;
         
        }
        if(Array.isArray(data.warnings_list)) {

            warnings =<div>
                        {
                            data.warnings_list.map((item,index)=>(
                                <>
                                    <div key={`${item.name}-warnings_list-index`}>Title :{item.name}</div>
                                </>

                            ))
                        }
                    </div>;
         
        }

        bodyText = <div  style={{overflowX:'hidden', overflowY:'auto',minHeight:'60px',maxHeight:'90px',marginBottom:'0.4rem'}}>
                {
                    data.malfunction_brake !== undefined && data.malfunction_brake === true
                    &&
                    <div style={{marginBottom:'0.3rem'}}>
                         <span style={{fontSize:'0.8rem',color:'#FF3239'}}>Brake on due to malfunctions.</span>
                    </div>
                }
            <div>
                <span style={{fontSize:'0.8rem',color:'black'}}>Erros:</span>
                <div style={{
                    position:'relative',
                    left:'1rem',
                }}>{errors}</div>
            </div>
            <div style={{marginBottom:'0.3rem'}}>
                 <span style={{fontSize:'0.8rem',color:'black'}}>Warnings:</span>
                <div style={{
                    position:'relative',
                    left:'1rem',
                }}>{warnings}</div>
            </div>
        </div>
    }


     
    const isCartEvent = event.event_type && (event.event_type === EVENT_TYPES.MULFUNCTION_EVENT  ||event.event_type === EVENT_TYPES.ROAD_EVENT ) ? true : false ;
    const isUserEvent = event.event_type  && (event.event_type === EVENT_TYPES.MISSED_CALL_EVENT || event.event_type === EVENT_TYPES.CART_UNAVAILABILITY_EVENT ) ? true : false

    const closeEvent = ()=> setHover(false);

    return(
        <div
            onClick={()=>setHover(!hover)} 
        >
        {
                hover
            ?
                <div className={styles.event_item_open} style={{height :blockedImg ?"318px" :null}}>
                    <div className={styles.event_item_close} >
                        <span className={styles.event_item_close_icon}>{icon}</span>
                        <span className={styles.event_item_close_title}>{event.event_name}{isCartEvent ? `, ${event.cart_id}` : ""}</span>
                        <span className={styles.event_item_close_time}>{houStr +":"+minStr}</span>
                    </div>
                    <div className={styles.event_item_open_body_header} >
                        {
                                isCartEvent
                            ?
                                "Cart " +event.cart_id 
                            :
                               isUserEvent
                            ?
                                `Mr ${event.name}`
                            :
                                null

                        }
                    </div>
                    <div className={styles.event_item_open_body_text}>
                        {   
                                event.event_type === EVENT_TYPES.MISSED_CALL_EVENT
                            ? 
                                <div>
                                    {   
                                            event.counter && event.counter >0 
                                        ?
                                            <span >
                                                Try Calling <b>{event.counter}</b> times.                                                    
                                            </span>
                                        :
                                            null
                                    }
                                    
                                </div>
                            :
                                <div className='black-scroll-bar-container' style={{
                                    minHeight:'30px'
                                }}>{bodyText}</div>
                        }
                        {       
                            blockedImg 
                        ?   <>
                                <img
                                    style={{marginTop:'10px'}}
                                    alt="blocked-path" 
                                    src={"data:image/png;base64," + blockedImg} 
                                    width={"240px"}
                                    height={"142px"}
                                />
                            </>
                        :
                            null
                        }   
                    </div>
                    <div >
                        <BottomActions 
                            closeEvent={closeEvent}
                            resolveEvent={resolveEvent}
                            focusOnCart={focusOnCart} 
                            eventId={eventId} 
                            cartId={isCartEvent ? event.cart_id:null}
                            userId={userId}
                            event_type={event.event_type}
                            callBack={callBack} 
                            eventName={event.event_name}
                            blockedImg={blockedImg}
                            assistCart={assistCart}
                            data={data}
                            showMalfunctionModal={showMalfunctionModal}
                            event={event}
                        />
                    </div>
                </div>
               
            :
                <div className={styles.event_item_close}>
                    <span className={styles.event_item_close_icon}>{icon}</span>
                    <span className={styles.event_item_close_title}>{event.event_name}{isCartEvent ? `, ${event.cart_id}` : ""}</span>
                    <span className={styles.event_item_close_time}>{houStr +":"+minStr}</span>
                </div>
            }
        </div>
       
    );
}